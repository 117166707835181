import { ExterneReferentieState } from "store/externe-referentie-state";
import { ExterneReferentieActions, SET_EXTERNE_REFERENTIE } from "../actions";

const initialState: ExterneReferentieState = {
  externeReferentie: undefined
};

const externeReferentieReducer = (
  state: ExterneReferentieState = initialState,
  action: ExterneReferentieActions
): ExterneReferentieState => {
  if (action && action.type == SET_EXTERNE_REFERENTIE) {
    return {
      ...state,
      externeReferentie: action.externeReferentie
    };
  } else {
    return state;
  }
};

export default externeReferentieReducer;
