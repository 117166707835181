import { FormikContextType } from "formik";
import { AntwoordBlokken } from "models/api";
import { connect } from "react-redux";
import { State } from "store";
import { copyVraagBlok } from "thunks";
import { CollectionCopy } from "./collection-copy.component";
import {
  CollectionCopyDispatchProps,
  CollectionCopyOwnProps,
  CollectionCopyStateProps
} from "./collection-copy.component.interfaces";

const mapStateToProps = (state: State, props: CollectionCopyOwnProps): CollectionCopyStateProps => ({
  hasCollectionValidationFailures: state.collections.list[props.collectionKey]?.validationFailures.length > 0 ?? false
});

const mapDispatchToProps = (dispatch: any, props: CollectionCopyOwnProps): CollectionCopyDispatchProps => ({
  onCopyVraagBlok: (antwoordBlokkenContext: FormikContextType<AntwoordBlokken>, copyIndex: number) => {
    dispatch(
      copyVraagBlok(
        props.collectionKey,
        props.template,
        antwoordBlokkenContext,
        props.getVraagBlokFromTemplate,
        copyIndex,
        props.copyAntwoorden
      )
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionCopy);
