import { Tekst } from "..";
import { jsonHelpers } from "../../../helpers";
import { Dictionary } from "../../application";
import { Meerkeuzevraag } from "../meerkeuzevraag";
import { VraagBlok } from "../vraag-blok";

export class MeldingsplichtVragen extends VraagBlok {
  constructor(
    public key: string,
    public volgnummer: number,
    public conditioneelGesteld: any,
    public conditioneelReadonly: any,
    public titel: Dictionary<string>,
    public meldingsplichtTitel: Tekst,
    public ongevalToelichtingParagraaf: Tekst,
    public watVoorOngeval: Meerkeuzevraag,
    public dodelijkOngeval: Meerkeuzevraag,
    public ernstigOngeval: Meerkeuzevraag,
    public arbeidsplaatsWijzigen: Meerkeuzevraag,
    public sporenVerloren: Meerkeuzevraag,
    public slachtofferNaarZiekenhuis: Meerkeuzevraag,
    public slachtofferBlijvendLetsel: Meerkeuzevraag,
    public geenMeldingsplicht: Meerkeuzevraag
  ) {
    super(key, volgnummer, conditioneelGesteld, conditioneelReadonly, titel);
  }

  static fromJson(key: string, json: any): MeldingsplichtVragen {
    return new MeldingsplichtVragen(
      key,
      json.volgnummer,
      json.conditioneelGesteld,
      json.conditioneelReadonly,
      json.titel,
      jsonHelpers.toTitel(`${key}.meldingsplichtTitel`, json.meldingsplichtTitel),
      jsonHelpers.toParagraaf(`${key}.ongevalToelichtingParagraaf`, json.ongevalToelichtingParagraaf),
      jsonHelpers.toMeerkeuzeVraag(`${key}.watVoorOngeval`, json.watVoorOngeval),
      jsonHelpers.toMeerkeuzeVraag(`${key}.dodelijkOngeval`, json.dodelijkOngeval),
      jsonHelpers.toMeerkeuzeVraag(`${key}.ernstigOngeval`, json.ernstigOngeval),
      jsonHelpers.toMeerkeuzeVraag(`${key}.arbeidsplaatsWijzigen`, json.arbeidsplaatsWijzigen),
      jsonHelpers.toMeerkeuzeVraag(`${key}.sporenVerloren`, json.sporenVerloren),
      jsonHelpers.toChecklistvraag(`${key}.slachtofferNaarZiekenhuis`, json.slachtofferNaarZiekenhuis),
      jsonHelpers.toChecklistvraag(`${key}.slachtofferBlijvendLetsel`, json.slachtofferBlijvendLetsel),
      jsonHelpers.toMeerkeuzeVraag(`${key}.geenMeldingsplicht`, json.geenMeldingsplicht)
    );
  }
}
