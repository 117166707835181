import { httpStatusCodes } from "constants/http-status-codes";
import { FormulierDefinitie } from "formulier-definitie";
import { MeldingBasisgegevens } from "models/api/melding/melding-basisgegevens";
import { push } from "redux-first-history";
import { ThunkDispatch } from "redux-thunk";
import { formulierDefinitieHelpers } from "../formulier-definitie/formulier-definitie-helper";
import { fileHelpers, getLanguage, meldingHelpers, processtapHelpers, userHelpers } from "../helpers";
import {
  AntwoordBlokken,
  Formulier,
  Melding,
  MeldingStatusResponse,
  Meldingrechten,
  Meldingstatus,
  MetadataGegevens,
  ValidationResponse
} from "../models/api";
import { Bestand } from "../models/api/blok/bestand";
import {
  BlokDefinitie,
  ExterneReferentie,
  Formuliercategorie,
  Formuliertype,
  Language,
  MeldingFile,
  MeldingFileState,
  Processtap,
  isFormulierTypeMelding
} from "../models/application";
import { FileError, FileErrors } from "../models/application/error";
import {
  clearFilesError,
  clearImportFileState,
  confirmIntrekkenAction,
  confirmWijzigenAction,
  formReinitialize,
  getFormulier,
  initAntwoorden,
  intrekkenMelding,
  resetFormulier,
  saveAntwoorden,
  setApiError,
  setAutorisatiegegevens,
  setBestanden,
  setFilesError,
  setIsInEditMode,
  setMelding,
  setProfielLoading,
  setSubmitProgress,
  setSubmitting,
  setSubmittingComplete,
  wijzigenMelding
} from "./../actions";
import { clearMelding, setDocumenten, setZaakLinks, vertalenMelding } from "./../actions/melding-actions";
import { initProces, setAllPagesComplete, setSubmitValidationResults } from "./../actions/proces-actions";
import { formulierApi, meldingApi, profielApi } from "./../api";
import { AppThunk } from "./app-thunk-type";
import { searchVestigingWithAutorisatieGegevensAndBedrijfsgegevens } from "./blok/vestiging-thunks";
import { requestUrlNavigation } from "./navigation-thunks";
import { checkProfileComplete, showGeenAvgAutorisatie, uitloggen } from "./security-thunks";
import { initializeVestigingenForFormulier } from "./thunk-helpers/vestiging-thunk-helper";
import i18n from "i18next";
import { translationKeys } from "../constants/translation-keys";

const updateMeldingState = (
  dispatch: ThunkDispatch<any, any, any>,
  res: MeldingStatusResponse,
  formuliertype: Formuliertype,
  taal: Language,
  emailadres?: string
) => {
  const meldingId = res.meldingId;
  const meldingnummer = res.meldingNummer;
  const versie = res.meldingVersie;
  const metadata = res.metadata;

  dispatch(
    setMelding({
      meldingId: meldingId,
      meldingnummer: meldingnummer,
      versie: versie,
      emailadres: emailadres,
      formuliertype: formuliertype,
      metadata: metadata,
      wordtGewijzigd: false,
      wordtVertaald: false,
      taal: taal
    })
  );
};

const handleSubmitComplete = async (
  dispatch: ThunkDispatch<any, any, any>,
  isUpdate: boolean,
  beforeSubmitCompleted?: () => Promise<void>,
  afterSubmitCompleted?: () => Promise<void>
) => {
  if (beforeSubmitCompleted) {
    await beforeSubmitCompleted();
  }

  dispatch(setSubmittingComplete(true));
  dispatch(resetFormulier());

  // Default melding created delegate is redirect to verstuurd
  afterSubmitCompleted =
    afterSubmitCompleted ||
    (async () => {
      dispatch(push(isUpdate ? "/gewijzigd" : "/verstuurd"));
    });

  await afterSubmitCompleted();
};

const handleSubmitFailed = (dispatch: ThunkDispatch<any, any, any>, response: any) => {
  dispatch(setSubmittingComplete(false));

  if (response instanceof FileErrors) {
    dispatch(setSubmitValidationResults(new ValidationResponse([])));
    dispatch(setFilesError(response));
  } else if (response.error === "login_required") {
    dispatch(uitloggen());
  } else if (response.status === httpStatusCodes.preconditionFailed) {
    const validationResponse = ValidationResponse.fromJson({
      validationFailures: response.response
    });

    dispatch(setSubmitValidationResults(validationResponse));
  }
};

export const submitMelding =
  (
    formuliertype: Formuliertype,
    createMelding: (
      blokken: AntwoordBlokken,
      taal: Language,
      meldingId: string | undefined,
      metadata: MetadataGegevens,
      externeReferentie: ExterneReferentie | undefined
    ) => Melding,
    getEmailadres?: (blokken: AntwoordBlokken) => string | undefined,
    beforeSubmitCompleted?: () => Promise<void>,
    afterSubmitCompleted?: () => Promise<void>
  ): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setSubmitting());
    dispatch(clearFilesError());

    const blokken = getState().antwoorden.blokken;
    const autorisatiegegevens = getState().autorisatie.autorisatiegegevens;
    const isVertaling = !!getState().melding?.wordtVertaald;
    const externeReferentie = getState().externeReferentie?.externeReferentie;

    // Only post the files that were added in this session
    const files: File[] = getState()
      .bestanden.files.filter((meldingFile) => meldingFile.state === MeldingFileState.Added && meldingFile.file != null)
      .map((meldingFile) => meldingFile.file) as File[];

    // Validate if the files can be read to prevent client errors that can occur when a selected file was modified before submitting the form
    const fileErrors: FileError[] = [];
    for (const file of files) {
      const checkFileResult = await fileHelpers.checkFileReadable(file);
      if (!checkFileResult.isReadable) {
        fileErrors.push(new FileError(file, checkFileResult.error.message));
      }
    }
    if (fileErrors.length > 0) {
      handleSubmitFailed(dispatch, new FileErrors(fileErrors));
    } else {
      const meldingId = getState().melding?.meldingId;
      const meldingStatus = getState().melding?.status;
      const metadata = new MetadataGegevens(autorisatiegegevens);
      const melding = createMelding(blokken, getLanguage(), meldingId, metadata, externeReferentie);
      const isExistingMelding = !!meldingId;

      const captcha = blokken.captcha as unknown as string;
      melding.captchaToken = captcha;

      const onSubmitProgress = (progress: number) => {
        dispatch(setSubmitProgress(progress));
      };

      const emailAdresProvider = () => (getEmailadres ? getEmailadres(blokken) : "");

      await meldingApi
        .createSaveMelding(
          isExistingMelding,
          isVertaling,
          formuliertype,
          onSubmitProgress
        )(melding, files)
        .then(async (res) => {
          if (isFormulierTypeMelding(formuliertype)) {
            updateMeldingState(dispatch, res, formuliertype, melding.taal, emailAdresProvider());
          }

          await handleSubmitComplete(
            dispatch,
            isExistingMelding && meldingStatus !== Meldingstatus.concept,
            beforeSubmitCompleted,
            afterSubmitCompleted
          );
        })
        .catch((response) => {
          handleSubmitFailed(dispatch, response);
        });
    }
  };

export const createConceptMelding =
  (formuliertype: Formuliertype, externeReferentie: ExterneReferentie): AppThunk<Promise<string | undefined>> =>
  async (dispatch, getState): Promise<string | undefined> => {
    dispatch(setSubmitting());

    const meldingBasisgegevens = new MeldingBasisgegevens(
      undefined,
      undefined,
      externeReferentie.datumAangemaakt,
      undefined,
      undefined,
      externeReferentie.ontvangstwijze,
      externeReferentie.externReferentienummer,
      externeReferentie.externReferentienummerType
    );

    const melding = meldingHelpers.constructNewMelding(
      formuliertype,
      meldingBasisgegevens,
      getState().autorisatie.autorisatiegegevens
    );

    const response = await meldingApi
      .createConcept(formuliertype)(melding, [])
      .catch(() => null);

    dispatch(setSubmittingComplete(response?.meldingId !== undefined));

    return response?.meldingId;
  };

export const getProfielMelding =
  (enableEdit: boolean): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setProfielLoading(true));

    const formulier = await formulierApi.getProfielFormulier();
    const melding = await profielApi.getMijnProfielMelding();
    const blokDefinitie = formulierDefinitieHelpers.getFormulierDefinitie(formulier);
    const procesStappen =
      enableEdit && blokDefinitie ? processtapHelpers.create(blokDefinitie, { excludeSamenvatting: true }) : undefined;

    await checkProfileComplete(dispatch, getState);

    dispatch(initializeFormulierAndMelding(melding, formulier, undefined, undefined, undefined, procesStappen));
    if (melding.metadata) {
      dispatch(
        searchVestigingWithAutorisatieGegevensAndBedrijfsgegevens(
          melding.metadata.autorisatiegegevens,
          melding.blokken.profiel.bedrijfsgegevens,
          formulier.blokken.profiel.bedrijfsgegevens.key
        )
      );
    }

    dispatch(setProfielLoading(false));
  };

export const setAutorisatieProfielIsInEditMode =
  (isInEditMode: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(setIsInEditMode(isInEditMode));
  };

export const getAutorisatieProfielMelding =
  (profielId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    dispatch(setProfielLoading(true));

    const enableEdit = getState().profiel.isInEditMode;
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getAutorisatieProfielFormulier(),
      profielApi.getAutorisatieProfielMelding(profielId),
      profielApi.getAutorisatieProfielMeldingrechten(profielId)
    ]);

    const blokDefinitie = formulierDefinitieHelpers.getFormulierDefinitie(formulier);

    const procesStappen =
      enableEdit && blokDefinitie ? processtapHelpers.create(blokDefinitie, { excludeSamenvatting: true }) : undefined;

    dispatch(initializeFormulierAndMelding(melding, formulier, undefined, undefined, meldingrechten, procesStappen));
    if (melding.metadata) {
      dispatch(
        searchVestigingWithAutorisatieGegevensAndBedrijfsgegevens(
          melding.metadata.autorisatiegegevens,
          melding.blokken.autorisatieProfiel.autorisatieProfiel.bedrijfsgegevens,
          formulier.blokken.autorisatieProfiel.autorisatieProfiel.bedrijfsgegevens.key
        )
      );
    }

    dispatch(setProfielLoading(false));
  };

  export const getWerkbakMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getWerkbakFormulier(),
      meldingApi.getWerkbakMelding(meldingId),
      meldingApi.getWerkbakMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        melding.blokken.werkzaamheden.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getWerkbakFormulierDefinitie)
      )
    );
  };

export const getDouaneMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getDouaneFormulier(),
      meldingApi.getDouaneMelding(meldingId),
      meldingApi.getDouaneMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        melding.blokken.bijlage.bestanden.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getDouaneFormulierDefinitie)
      )
    );
  };

export const getDuikarbeidMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getDuikarbeidFormulier(),
      meldingApi.getDuikarbeidMelding(meldingId),
      meldingApi.getDuikarbeidMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        melding.blokken.werkzaamheden.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getDuikarbeidFormulierDefinitie)
      )
    );
  };

export const getOntheffingLiftenMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getOntheffingLiftenFormulier(),
      meldingApi.getOntheffingLiftenMelding(meldingId),
      meldingApi.getOntheffingLiftenMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.aanvrager.melder?.emailadres.waarde,
        melding.blokken.aanvraag.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getOntheffingLiftenFormulierDefinitie)
      )
    );
  };

export const getOntheffingNachtarbeidMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getOntheffingNachtarbeidFormulier(),
      meldingApi.getOntheffingNachtarbeidMelding(meldingId),
      meldingApi.getOntheffingNachtarbeidMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.inleiding.gegevensAanvragerOntheffingNachtarbeid.aanvrager?.emailadres.waarde,
        melding.blokken.omstandigheden.bijlage.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getOntheffingNachtarbeidFormulierDefinitie)
      )
    );
  };

export const getVuurOpTankschipMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getVuurOpTankschipFormulier(),
      meldingApi.getVuurOpTankschipMelding(meldingId),
      meldingApi.getVuurOpTankschipMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.aanvrager.melder?.emailadres.waarde,
        melding.blokken.verklaringen.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getVuurOpTankschipFormulierDefinitie)
      )
    );
  };

export const getAsbestRisicoklasse1Melding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getAsbestRisicoklasse1Formulier(),
      meldingApi.getAsbestRisicoklasse1Melding(meldingId),
      meldingApi.getAsbestRisicoklasse1Meldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        melding.blokken.werkzaamheden.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getAsbestRisicoklasse1FormulierDefinitie)
      )
    );
  };

export const getBouwprocesMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getBouwprocesFormulier(),
      meldingApi.getBouwprocesMelding(meldingId),
      meldingApi.getBouwprocesMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        melding.blokken.bouwwerk.planningBestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getBouwprocesFormulierDefinitie)
      )
    );
  };

export const getContactformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getContactFormulier(),
      meldingApi.getContactMelding(meldingId),
      meldingApi.getContactMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.contactMelder?.melder?.melder?.emailadres.waarde,
        melding.blokken.contactVraag.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getContactFormulierDefinitie)
      )
    );
  };

export const getKlachtformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getKlachtFormulier(),
      meldingApi.getKlachtMelding(meldingId),
      meldingApi.getKlachtMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.common?.melder?.emailadres.waarde,
        melding.blokken.klacht.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getKlachtFormulierDefinitie)
      )
    );
  };

export const getKlachtenformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getKlachtenFormulier(),
      meldingApi.getKlachtenMelding(meldingId),
      meldingApi.getKlachtenMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.anoniemMelder?.common?.melder?.emailadres.waarde,
        melding.blokken.bijlage.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getKlachtenFormulierDefinitie)
      )
    );
  };

export const getOngevalformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const isInternalUser = userHelpers.getIsInternalUser(getState().security.user);

    // Ongeval returns 403 for non internal user, but there is no distinction between a normal 403 or an AVG 403
    // therefor this code always shows a Avg screen to non-internal users
    if (isInternalUser) {
      const [formulier, melding, meldingrechten] = await Promise.all([
        formulierApi.getOngevalFormulier(),
        meldingApi.getOngevalMelding(meldingId),
        meldingApi.getOngevalMeldingrechten(meldingId)
      ]);

      dispatch(
        initializeFormulierAndMelding(
          melding,
          formulier,
          melding.blokken.melder.common?.melder?.emailadres.waarde,
          melding.blokken.meldingsplichtToedracht.toedracht.beeldmateriaalBestanden.waarde,
          meldingrechten,
          getInitialProcesstappen(melding, formulier, FormulierDefinitie.getOngevalFormulierDefinitie)
        )
      );
    } else {
      dispatch(showGeenAvgAutorisatie());
    }
  };

export const getProductveiligheidformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getProductveiligheidFormulier(),
      meldingApi.getProductveiligheidMelding(meldingId),
      meldingApi.getProductveiligheidMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.anoniemMelder?.common?.melder?.emailadres.waarde,
        melding.blokken.productgegevens.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getProductveiligheidFormulierDefinitie)
      )
    );
  };

export const getBiologischeAgentiaMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getBiologischeAgentiaFormulier(),
      meldingApi.getBiologischeAgentiaMelding(meldingId),
      meldingApi.getBiologischeAgentiaMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        melding.blokken.werkzaamheden.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getBiologischeAgentiaFormulierDefinitie)
      )
    );
  };

export const getVluchtigeOrganischeStoffenformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getVluchtigeOrganischeStoffenFormulier(),
      meldingApi.getVluchtigeOrganischeStoffenMelding(meldingId),
      meldingApi.getVluchtigeOrganischeStoffenMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.aanvrager.common?.melder?.emailadres.waarde,
        melding.blokken.motivatie.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getVluchtigeOrganischeStoffenFormulierDefinitie)
      )
    );
  };

export const getReactieOpenbaarmakingFormulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getReactieOpenbaarmakingFormulier(),
      meldingApi.getReactieOpenbaarmakingMelding(meldingId),
      meldingApi.getReactieOpenbaarmakingMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.reactie.reageerder?.emailadres.waarde,
        [],
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getReactieOpenbaarmakingFormulierDefinitie)
      )
    );
  };

export const getUploadGeneriekformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getUploadGeneriekFormulier(),
      meldingApi.getUploadGeneriekMelding(meldingId),
      meldingApi.getUploadGeneriekMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.upload.emailadres.waarde,
        [],
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getUploadGeneriekFormulierDefinitie)
      )
    );
  };

export const getUploadRieformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getUploadRieFormulier(),
      meldingApi.getUploadRieMelding(meldingId),
      meldingApi.getUploadRieMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.upload.contactpersoon?.emailadres.waarde,
        [],
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getUploadRieFormulierDefinitie)
      )
    );
  };

export const getCbiformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getCbiFormulier(),
      meldingApi.getCbiMelding(meldingId),
      meldingApi.getCbiMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.common?.melder?.emailadres.waarde,
        melding.blokken.bijlage.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getCbiFormulierDefinitie)
      )
    );
  };

export const getOntheffingKinderarbeidFormulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getOntheffingKinderarbeidFormulier(),
      meldingApi.getOntheffingKinderarbeidMelding(meldingId),
      meldingApi.getOntheffingKinderarbeidMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        [],
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getOntheffingKinderarbeidFormulierDefinitie)
      )
    );
  };

export const getAsbestverwijderingLavsFormulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getAsbestverwijderingLavsFormulier(),
      meldingApi.getAsbestverwijderingLavsMelding(meldingId),
      meldingApi.getAsbestverwijderingLavsMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.contactpersoon?.emailadres.waarde,
        [],
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getAsbestverwijderingLavsFormulierDefinitie)
      )
    );
  };

export const getAsbestverwijderingLavsV2FormulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getAsbestverwijderingLavsV2Formulier(),
      meldingApi.getAsbestverwijderingLavsV2Melding(meldingId),
      meldingApi.getAsbestverwijderingLavsV2Meldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.contactpersoon?.emailadres.waarde,
        [],
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getAsbestverwijderingLavsV2FormulierDefinitie)
      )
    );
  };

export const getAsbestverwijderingMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getAsbestverwijderingFormulier(),
      meldingApi.getAsbestverwijderingMelding(meldingId),
      meldingApi.getAsbestverwijderingMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        [],
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getAsbestverwijderingFormulierDefinitie)
      )
    );
  };

export const getAanwijzingRisicovolleBedrijvenMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getAanwijzingRisicovolleBedrijvenFormulier(),
      meldingApi.getAanwijzingRisicovolleBedrijvenMelding(meldingId),
      meldingApi.getAanwijzingRisicovolleBedrijvenMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        [],
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getAanwijzingRisicovolleBedrijvenFormulierDefinitie)
      )
    );
  };

export const getBergplaatsRadioactieveStoffenMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getBergplaatsRadioactieveStoffenFormulier(),
      meldingApi.getBergplaatsRadioactieveStoffenMelding(meldingId),
      meldingApi.getBergplaatsRadioactieveStoffenMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        [],
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getBergplaatsRadioactieveStoffenFormulierDefinitie)
      )
    );
  };

export const getGevaarlijkeGassenImportcontainersMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getGevaarlijkeGassenImportcontainersFormulier(),
      meldingApi.getGevaarlijkeGassenImportcontainersMelding(meldingId),
      meldingApi.getGevaarlijkeGassenImportcontainersMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        [],
        meldingrechten,
        getInitialProcesstappen(
          melding,
          formulier,
          FormulierDefinitie.getGevaarlijkeGassenImportcontainersFormulierDefinitie
        )
      )
    );
  };

export const getGevaarlijkeLiftMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getGevaarlijkeLiftFormulier(),
      meldingApi.getGevaarlijkeLiftMelding(meldingId),
      meldingApi.getGevaarlijkeLiftMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        [],
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getGevaarlijkeLiftFormulierDefinitie)
      )
    );
  };

export const getHandelingenIoniserendeStralingMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getHandelingenIoniserendeStralingFormulier(),
      meldingApi.getHandelingenIoniserendeStralingMelding(meldingId),
      meldingApi.getHandelingenIoniserendeStralingMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        [],
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getHandelingenIoniserendeStralingFormulierDefinitie)
      )
    );
  };

export const getOngewoneVoorvallenMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getOngewoneVoorvallenFormulier(),
      meldingApi.getOngewoneVoorvallenMelding(meldingId),
      meldingApi.getOngewoneVoorvallenMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        [],
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getOngewoneVoorvallenFormulierDefinitie)
      )
    );
  };

export const getArieformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getArieFormulier(),
      meldingApi.getArieMelding(meldingId),
      meldingApi.getArieMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        melding.blokken.bijlage.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getArieFormulierDefinitie)
      )
    );
  };

export const getBibobformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getBibobFormulier(),
      meldingApi.getBibobMelding(meldingId),
      meldingApi.getBibobMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.common?.melder?.emailadres.waarde,
        melding.blokken.bijlage.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getBibobFormulierDefinitie)
      )
    );
  };

export const getInformatieverzoekformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getInformatieverzoekFormulier(),
      meldingApi.getInformatieverzoekMelding(meldingId),
      meldingApi.getInformatieverzoekMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.common?.melder?.emailadres.waarde,
        melding.blokken.bijlage.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getInformatieverzoekFormulierDefinitie)
      )
    );
  };

export const getWooformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getWooFormulier(),
      meldingApi.getWooMelding(meldingId),
      meldingApi.getWooMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.common?.melder?.emailadres.waarde,
        melding.blokken.bijlage.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getWooFormulierDefinitie)
      )
    );
  };

export const getPredicaatKoninklijkformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getPredicaatKoninklijkFormulier(),
      meldingApi.getPredicaatKoninklijkMelding(meldingId),
      meldingApi.getPredicaatKoninklijkMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.aanvrager.melder?.emailadres.waarde,
        melding.blokken.bijlage.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getPredicaatKoninklijkFormulierDefinitie)
      )
    );
  };

export const getVerzoekTotGezamenlijkeInterventieformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getVerzoekTotGezamenlijkeInterventieFormulier(),
      meldingApi.getVerzoekTotGezamenlijkeInterventieMelding(meldingId),
      meldingApi.getVerzoekTotGezamenlijkeInterventieMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.common?.melder?.emailadres.waarde,
        melding.blokken.bijlage.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(
          melding,
          formulier,
          FormulierDefinitie.getVerzoekTotGezamenlijkeInterventieFormulierDefinitie
        )
      )
    );
  };

export const getOntheffingVuurOpTankschipformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getOntheffingVuurOpTankschipFormulier(),
      meldingApi.getOntheffingVuurOpTankschipMelding(meldingId),
      meldingApi.getOntheffingVuurOpTankschipMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.aanvrager.melder?.emailadres.waarde,
        melding.blokken.bijlage.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getOntheffingVuurOpTankschipFormulierDefinitie)
      )
    );
  };

export const getAvvArtikel10formulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getAvvArtikel10Formulier(),
      meldingApi.getAvvArtikel10Melding(meldingId),
      meldingApi.getAvvArtikel10Meldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.melder.melder?.emailadres.waarde,
        melding.blokken.bijlage.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getAvvArtikel10FormulierDefinitie)
      )
    );
  };

export const getAvvArtikel10AVerzoekformulierMelding =
  (meldingId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const [formulier, melding, meldingrechten] = await Promise.all([
      formulierApi.getAvvArtikel10AVerzoekFormulier(),
      meldingApi.getAvvArtikel10AVerzoekMelding(meldingId),
      meldingApi.getAvvArtikel10AVerzoekMeldingrechten(meldingId)
    ]);

    dispatch(
      initializeFormulierAndMelding(
        melding,
        formulier,
        melding.blokken.verzoeker?.melder?.emailadres.waarde,
        melding.blokken.bijlage.bestanden.waarde,
        meldingrechten,
        getInitialProcesstappen(melding, formulier, FormulierDefinitie.getAvvArtikel10AVerzoekFormulierDefinitie)
      )
    );
  };

const initializeFormulierAndMelding =
  <TFormulier extends Formulier>(
    melding: Melding,
    formulier: TFormulier,
    emailadres?: string,
    bestanden?: Bestand[],
    meldingrechten?: Meldingrechten,
    overrideInitProcesWith?: Processtap[]
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(resetFormulier());
    dispatch(getFormulier(formulier));

    if (meldingrechten?.hasExternalServiceError) {
      const errorMessage = i18n.t(translationKeys.blok.samenvatting.wijzigenNietBeschikbaar);
      dispatch(setApiError(errorMessage));
    }
    if (melding.id && melding.basisgegevens.nummer != null) {
      dispatch(setAutorisatiegegevens(melding.metadata.autorisatiegegevens));

      dispatch(
        setMelding({
          meldingId: melding.id,
          meldingnummer: melding.basisgegevens.nummer ?? 0,
          versie: melding.versie,
          emailadres: emailadres,
          formuliertype: formulier.type,
          meldingrechten: meldingrechten,
          datumIngediend: melding.basisgegevens.aanmaakDateTime,
          datumIntrekking: melding.basisgegevens.datumIntrekking,
          verzenddatum: melding.verzenddatum,
          status: melding.basisgegevens.status,
          metadata: melding.metadata,
          wordtGewijzigd: false,
          wordtVertaald: false,
          taal: melding.taal
        })
      );
    }

    dispatch(initAntwoorden(melding.blokken, formulier.type, melding.id));

    dispatch(initializeVestigingenForFormulier(formulier, melding.blokken));

    const overridingProcesstapHelperWith = overrideInitProcesWith ?? [processtapHelpers.createSamenvatting(true)];
    dispatch(initProces(overridingProcesstapHelperWith));

    const files = bestanden?.map((bestand) => {
      return new MeldingFile(MeldingFileState.Untouched, bestand.naam, bestand.grootte, null, bestand.id);
    });
    if (files) {
      dispatch(setBestanden(files));
    }
  };

const getInitialProcesstappen = <TFormulier extends Formulier>(
  melding: Melding,
  formulier: TFormulier,
  getFormulierDefinitie: (formulier: TFormulier) => BlokDefinitie[]
): Processtap[] | undefined =>
  melding.basisgegevens.status === Meldingstatus.concept
    ? processtapHelpers.create(getFormulierDefinitie(formulier))
    : undefined;

export const meldingBewerken =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = getState().vragen.formulier;
    if (formulier) {
      if (formulier.categorie === Formuliercategorie.Aanvraag || formulier.categorie === Formuliercategorie.Verzoek) {
        dispatch(wijzigenMelding());
      } else {
        dispatch(confirmWijzigen());
      }
    }
  };

export const confirmWijzigen =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const formulier = getState().vragen.formulier;
    if (formulier) {
      const formulierDefinitie = formulierDefinitieHelpers.getFormulierDefinitie(formulier);
      if (formulierDefinitie !== null) {
        const processtappen = processtapHelpers.create(formulierDefinitie);

        dispatch(initProces(processtappen));

        dispatch(setAllPagesComplete());

        dispatch(clearImportFileState());
      }

      const antwoorden = getState().antwoorden.blokken;

      const resetAntwoordenBijMeldingWijzigenResult = meldingHelpers.resetAntwoordenBijMeldingWijzigen(
        antwoorden,
        formulier.blokken
      );

      if (resetAntwoordenBijMeldingWijzigenResult.areAnyAntwoordenReset) {
        dispatch(saveAntwoorden(resetAntwoordenBijMeldingWijzigenResult.antwoordBlokken));

        dispatch(formReinitialize());
      }
    }
    dispatch(confirmWijzigenAction());
  };

export const meldingKopieren =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const melding = getState().melding;
    dispatch(clearMelding());
    dispatch(resetFormulier());
    dispatch(push(`/${melding.formuliertype.toLowerCase()}/${melding.meldingId}/kopie`));
  };

export const meldingIntrekken =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(intrekkenMelding());
  };

export const confirmIntrekken =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const state = getState().melding;
    if (state.meldingId) {
      await meldingApi.intrekkenMelding(getState().melding.formuliertype, state.meldingId);
      dispatch(confirmIntrekkenAction());
      dispatch(requestUrlNavigation(`/ingetrokken`));
    }
  };

export const meldingVertalen = (): AppThunk => async (dispatch, getState) => {
  dispatch(vertalenMelding());
  const formulier = getState().vragen.formulier;
  if (formulier) {
    const formulierDefinitie = formulierDefinitieHelpers.getFormulierDefinitie(formulier);
    if (formulierDefinitie !== null) {
      const processtappen = processtapHelpers.create(formulierDefinitie);

      dispatch(initProces(processtappen));

      dispatch(setAllPagesComplete());
    }
  }
};

export const getZaakLinks =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const meldingId = getState().melding?.meldingId;
    if (meldingId) {
      const zaakLinks = await meldingApi.getZaken(meldingId);
      dispatch(setZaakLinks(zaakLinks.items));
      if (zaakLinks.hasExternalServiceError) {
        const errorMessage = i18n.t(translationKeys.blok.samenvatting.zaaklinksNietBeschikbaar);
        dispatch(setApiError(errorMessage));
      }
    }
  };

export const koppelZaak =
  (): AppThunk =>
  async (_dispatch, getState): Promise<void> => {
    const meldingId = getState().melding.meldingId;
    const user = getState().security.user;
    if (meldingId && user) {
      const link = meldingApi.getKoppelZaakLink(meldingId);
      window.open(link);
    }
  };

export const getDocumenten =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const meldingId = getState().melding.meldingId;
    if (meldingId) {
      const documenten = await meldingApi.getDocumenten(meldingId);
      dispatch(setDocumenten(documenten.items));
      if (documenten.hasExternalServiceError) {
        const errorMessage = i18n.t(translationKeys.blok.samenvatting.documentenNietBeschikbaar);
        dispatch(setApiError(errorMessage));
      }
    }
  };
